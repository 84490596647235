import { Link, useHistory, useLocation } from "react-router-dom";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Map, { Building, MapView } from "./components/map/Map";
import usePromise from "./utils/usePromise";
import "./Root.scss";
import { IndicatorContext } from "./components/IndicatorContext";
import EditObservation from "./components/EditObservation";
import OpenQuestions from "./components/OpenQuestions";
import { useLogout } from "./components/UserSessionContext";
import LatLng from "./models/LatLng";
import { ApiService } from "./api";
import OpenQuestion from "./models/OpenQuestion";
import Observation, { NewObservation } from "./models/Observation";
import useDependentState from "./utils/useDependentState";
import UserData from "./models/UserData";
import RootRoute from "./RootRoute";
import logoutSvg from "./components/images/logout.svg";
import userDataFromApi from "./models/api/UserData";
export default function Root() {
  const history = useHistory();
  const route = useRoute();

  // Load data from API
  const [userData] = usePromise<UserData>({
    initial: null,
    async promise() {
      return userDataFromApi(await ApiService.me());
    },
  });
  const categories = userData?.categories ?? [];
  const area = userData?.area ?? null;

  // Keep local copy of observations and open questions
  const [observations, setObservations] = useDependentState<Observation[]>(
    userData?.observations ?? []
  );
  const [openQuestions, setOpenQuestions] = useDependentState<OpenQuestion[]>(
    userData?.openQuestions ?? []
  );

  const { bottomUIHeight } = useContext(IndicatorContext);
  const logout = useLogout();

  const [editObservation, setEditObservation] = useState<
    Observation | NewObservation | null
  >(null);

  // Set edit observation when route changes
  useEffect(() => {
    if (route.type === "newObservation") {
      setEditObservation({
        coordinate: route.coordinate,
        indicator: null,
        bagId: route.building?.bagId ?? null,
        address: route.building?.address ?? {
          street: "",
          houseNumber: "",
          postcode: "",
          city: "",
          isExact: false,
        },
        entries: [
          {
            id: null,
            created: new Date(),
            comment: "",
            photo: null,
          },
        ],
      });
    } else if (route.type === "editObservation") {
      setEditObservation(observations.find((o) => o.id === route.id) ?? null);
    } else {
      setEditObservation(null);
    }
  }, [observations, route]);

  let focusCoordinate: LatLng | null =
    editObservation?.coordinate ??
    (route.type === "questions" ? area?.center ?? null : null);

  const initialView = useMemo<MapView | null>(() => {
    const hasCachedBounds =
      window.localStorage.getItem("map_center_lat") !== null;

    if (hasCachedBounds) {
      return {
        center: {
          lat: Number.parseFloat(
            window.localStorage.getItem("map_center_lat") ?? "52.316667"
          ),
          lng: Number.parseFloat(
            window.localStorage.getItem("map_center_lng") ?? "5.55"
          ),
        },
        zoom: Number.parseFloat(window.localStorage.getItem("map_zoom") ?? "9"),
      };
    } else {
      if (area === null) return null;
      return { center: area.center, zoom: 13 };
    }
  }, [area]);

  const editObservationId =
    editObservation !== null && "id" in editObservation
      ? editObservation.id
      : null;
  const smallMarkers = useMemo(() => {
    return observations
      .filter((o) =>
        editObservationId !== null ? o.id !== editObservationId : true
      )
      .map((o) => ({
        id: o.id,
        coordinate: o.coordinate,
        code: o.indicator.code,
        bagId: o.bagId,
      }));
  }, [editObservationId, observations]);

  const selectedMarker = useMemo(() => {
    if (!editObservation?.coordinate) return null;
    return {
      code: editObservation?.indicator?.code ?? " ",
      coordinate: editObservation.coordinate,
      bagId: editObservation.bagId,
      draggable: editObservation.bagId === null,
    };
  }, [
    editObservation?.indicator?.code,
    editObservation?.coordinate,
    editObservation?.bagId,
  ]);

  const onSelectedMarkerDragged = useCallback((coordinate: LatLng) => {
    setEditObservation((o) => {
      if (o === null) return null;
      return { ...o, coordinate };
    });
  }, []);

  const onSmallMarkerClicked = useCallback(
    (id: string) => {
      if (route?.type === "root") {
        history.replace(`/observation/${id}`);
      }
    },
    [history, route?.type]
  );

  const onAreaMarkerClicked = useCallback(() => {
    history.replace("/questions");
  }, [history]);

  const selectionMode = userData?.selectionMode;
  const onMapClicked = useCallback(
    (coordinate: LatLng, building: Building | null) => {
      if (route?.type === "root") {
        // Ignore clicks outside buildings if not on a building
        if (selectionMode === "bag" && building === null) return;

        history.replace(
          `/observation/new?lat=${coordinate.lat.toFixed(
            6
          )}&lng=${coordinate.lng.toFixed(6)}`,
          building
        );
      }
    },
    [history, route?.type, selectionMode]
  );

  const areas = useMemo(() => (area === null ? [] : [area]), [area]);

  return (
    <>
      <Map
        initialView={initialView}
        availableHeight={100 - bottomUIHeight}
        smallMarkers={smallMarkers}
        selectedMarker={selectedMarker}
        onSelectedMarkerDragged={onSelectedMarkerDragged}
        onSmallMarkerClicked={onSmallMarkerClicked}
        focusCoordinate={focusCoordinate}
        onMapClicked={onMapClicked}
        selectionMode={userData?.selectionMode ?? "coordinate"}
        areas={areas}
        onAreaMarkerClicked={onAreaMarkerClicked}
        selectedArea={route.type === "questions" ? area : null}
        showGeoLocationMarker={true}
      />
      <button
        id="logout"
        onClick={() => {
          if (window.confirm("Wil je uitloggen?")) {
            logout();
          }
        }}
      >
        <img src={logoutSvg} alt="logout" />
      </button>
      <div
        id="bottom-ui"
        style={{
          height: `${bottomUIHeight}vh`,
          opacity: bottomUIHeight === 0 ? 0.0 : 1.0,
        }}
      >
        <Link to="/" id="close" replace>
          X
        </Link>

        {(() => {
          switch (route.type) {
            case "root":
              return <RootRoute />;

            case "newObservation":
              if (editObservation === null) return null;
              return (
                <EditObservation
                  readonly={false}
                  categories={categories}
                  onObservationSaved={(newObservation) => {
                    setObservations([...observations, newObservation]);
                    history.replace("/");
                  }}
                  observation={editObservation}
                  onObservationChanged={setEditObservation}
                  onObservationDeleted={() => {}}
                />
              );

            case "editObservation":
              if (editObservation === null) return null;
              return (
                <EditObservation
                  readonly={false}
                  categories={categories}
                  observation={editObservation}
                  onObservationChanged={setEditObservation}
                  onObservationSaved={(savedObservation) => {
                    setObservations(
                      observations.map((i) =>
                        i.id === savedObservation.id ? savedObservation : i
                      )
                    );
                    history.replace("/");
                  }}
                  onObservationDeleted={(id) => {
                    setObservations(observations.filter((o) => o.id !== id));
                    history.replace("/");
                  }}
                />
              );

            case "questions":
              return (
                <OpenQuestions
                  questions={openQuestions}
                  setQuestions={setOpenQuestions}
                  readonly={false}
                />
              );
          }
        })()}
      </div>
    </>
  );
}

type AppRoute =
  | { type: "root" }
  | { type: "questions" }
  | { type: "newObservation"; coordinate: LatLng; building: Building | null }
  | { type: "editObservation"; id: string };

function useRoute(): AppRoute {
  const location = useLocation<Building | null>();

  return useMemo(() => {
    if (location.pathname === "/observation/new") {
      const params = new URLSearchParams(location.search);
      return {
        type: "newObservation",
        coordinate: {
          lat: Number.parseFloat(params.get("lat") ?? "0"),
          lng: Number.parseFloat(params.get("lng") ?? "0"),
        },
        building: location.state,
      };
    } else if (location.pathname.startsWith("/observation/")) {
      return {
        type: "editObservation",
        id: location.pathname.substring("/observation/".length),
      };
    } else if (location.pathname === "/questions") {
      return { type: "questions" };
    } else {
      return { type: "root" };
    }
  }, [location]);
}
