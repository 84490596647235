/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { Login } from '../models/Login';
import type { Observation } from '../models/Observation';
import type { OpenQuestion } from '../models/OpenQuestion';
import type { Token } from '../models/Token';
import type { UserData } from '../models/UserData';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * Login endpoint for users
     * @param lat
     * @param lng
     * @returns Address
     * @throws ApiError
     */
    public static async geocode(
        lat: number,
        lng: number,
    ): Promise<Address> {
        const result = await __request({
            method: 'GET',
            path: `/api/geocode`,
            query: {
                'lat': lat,
                'lng': lng,
            },
        });
        return result.body;
    }

    /**
     * @returns UserData
     * @throws ApiError
     */
    public static async me(): Promise<UserData> {
        const result = await __request({
            method: 'GET',
            path: `/api/me`,
        });
        return result.body;
    }

    /**
     * @param id
     * @returns UserData
     * @throws ApiError
     */
    public static async userData(
        id: string,
    ): Promise<UserData> {
        const result = await __request({
            method: 'GET',
            path: `/api/users/${id}`,
        });
        return result.body;
    }

    /**
     * Login endpoint for users
     * @param id
     * @param file
     * @returns any
     * @throws ApiError
     */
    public static async retrievePhoto(
        id: string,
        file: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/entries/${id}/photo/${file}`,
        });
        return result.body;
    }

    /**
     * Login endpoint for users
     * @param requestBody
     * @returns Token
     * @throws ApiError
     */
    public static async login(
        requestBody?: Login,
    ): Promise<Token> {
        const result = await __request({
            method: 'POST',
            path: `/api/auth/login`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns OpenQuestion
     * @throws ApiError
     */
    public static async updateOpenQuestions(
        requestBody?: Array<OpenQuestion>,
    ): Promise<Array<OpenQuestion>> {
        const result = await __request({
            method: 'POST',
            path: `/api/openquestions/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Observation
     * @throws ApiError
     */
    public static async createObservation(
        requestBody?: any,
    ): Promise<Observation> {
        const result = await __request({
            method: 'POST',
            path: `/api/observations/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns Observation
     * @throws ApiError
     */
    public static async updateObservation(
        id: string,
        requestBody?: any,
    ): Promise<Observation> {
        const result = await __request({
            method: 'PUT',
            path: `/api/observations/${id}/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static async destroyObservation(
        id: string,
        requestBody?: any,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/observations/${id}/`,
            body: requestBody,
        });
        return result.body;
    }

}