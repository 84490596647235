import LatLng from "../../models/LatLng";
import React, { useCallback } from "react";
import { Marker, MarkerDragEvent } from "react-map-gl";

export default function SelectedMarker({
  selectedMarker,
  onSelectedMarkerDragged,
}: {
  selectedMarker: { code: string; coordinate: LatLng; draggable: boolean };
  onSelectedMarkerDragged(coordinate: LatLng): void;
}) {
  const onDragEnd = useCallback(
    (e: MarkerDragEvent) => {
      onSelectedMarkerDragged(e.lngLat);
    },
    [onSelectedMarkerDragged]
  );
  return (
    <Marker
      latitude={selectedMarker.coordinate.lat}
      longitude={selectedMarker.coordinate.lng}
      draggable={selectedMarker.draggable}
      onDragEnd={onDragEnd}
    >
      <div className="selected-marker">{selectedMarker.code}</div>
    </Marker>
  );
}
