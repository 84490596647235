import LatLng from "../../models/LatLng";
import { Layer, Source } from "react-map-gl";
import { GeoJSON } from "geojson";
import React from "react";

export default function ObservationMarkers({
  smallMarkers,
}: {
  smallMarkers: Array<{
    id: string;
    code: string;
    coordinate: LatLng;
  }>;
}) {
  return (
    <Source
      id="observations"
      type="geojson"
      data={{
        type: "FeatureCollection",
        features: smallMarkers.map(
          (marker): GeoJSON.Feature => ({
            type: "Feature",
            properties: { code: marker.code, id: marker.id },
            geometry: {
              type: "Point",
              coordinates: [marker.coordinate.lng, marker.coordinate.lat],
            },
          })
        ),
      }}
      cluster={true}
      clusterRadius={16}
    >
      {/* Clusters */}
      <Layer
        id="cluster-circles"
        type="circle"
        filter={["has", "point_count"]}
        paint={{
          "circle-radius": 13,
          "circle-color": "#008e00",
          "circle-stroke-color": "rgba(0,145,0,0.66)",
          "circle-stroke-width": 2,
        }}
      />
      <Layer
        id="cluster-texts"
        type="symbol"
        filter={["has", "point_count"]}
        layout={{
          "text-field": "{point_count_abbreviated}",
          "text-size": 12,
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": "white",
        }}
      />

      {/* Observations */}
      <Layer
        id="observation-circles"
        type="circle"
        filter={["!", ["has", "point_count"]]}
        paint={{ "circle-radius": 10, "circle-color": "#070" }}
      />
      <Layer
        id="observation-texts"
        type="symbol"
        filter={["!", ["has", "point_count"]]}
        layout={{
          "text-field": "{code}",
          "text-size": 12,
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": "white",
        }}
      />
    </Source>
  );
}
