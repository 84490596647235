import LatLng from "../../models/LatLng";
import { MapboxMap, useMap } from "react-map-gl";
import { DependencyList, useCallback, useEffect } from "react";
import mapboxgl from "mapbox-gl";

export function ObservationBuildings({
  selectedMarker,
  smallMarkers,
}: {
  selectedMarker: {
    code: string;
    coordinate: LatLng;
    bagId: string | null;
    draggable: boolean;
  } | null;
  smallMarkers: Array<{
    id: string;
    code: string;
    coordinate: LatLng;
    bagId: string | null;
  }>;
}) {
  useMapEffect(
    (map) => {
      const identifations = smallMarkers
        .map((m) => m.bagId)
        .filter((id) => id !== null);

      map.setFilter("bag_with_observation", [
        "in",
        "identificatie",
        ...identifations,
      ]);
    },
    [smallMarkers]
  );

  useMapEffect(
    (map) => {
      map.setFilter(
        "bag_selected",
        selectedMarker !== null && selectedMarker.bagId !== null
          ? ["==", "identificatie", selectedMarker.bagId]
          : ["boolean", false]
      );
    },
    [selectedMarker]
  );

  return null;
}

function useMapEffect(
  effect: (map: mapboxgl.Map) => void,
  deps?: DependencyList
) {
  const mapRef = useMap();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(effect, deps ?? [effect]);
  const mapRefCurrent = mapRef.current;
  useEffect(() => {
    const map: MapboxMap | undefined = mapRefCurrent?.getMap();

    if (map !== undefined) {
      // @ts-ignore
      if (map.style && map.style._loaded) {
        cb(map);
      } else {
        const callEffect = () => {
          cb(map);
        };

        map.once("styledata", callEffect);
        return () => {
          map.off("styledata", callEffect);
        };
      }
    }
  }, [cb, mapRefCurrent]);
}
