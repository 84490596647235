import { UserData as ApiUserData } from "../../api";
import UserData, { SelectionMode } from "../UserData";
import Observation from "../Observation";
import { Polygon } from "geojson";
import LatLng from "../LatLng";
import polylabel from "polylabel";
import { observationFromApi } from "./Observation";

export default function userDataFromApi(apiData: ApiUserData): UserData {
  const indicators = apiData.categories.flatMap((c) => c.indicators);

  return {
    area: { ...apiData.area, center: getLabelCenter(apiData.area.shape) },
    categories: apiData.categories,
    observations: apiData.observations.map<Observation>((o) =>
      observationFromApi(
        o,
        indicators[indicators.findIndex((i) => i.code === o.indicator)]
      )
    ),
    openQuestions: apiData.open_questions,
    selectionMode: apiData.selection_mode as SelectionMode,
  };
}
function getLabelCenter(polygon: Polygon): LatLng {
  const labelPosition = polylabel(polygon.coordinates, 0.001);

  return {
    lng: labelPosition[0],
    lat: labelPosition[1],
  };
}
