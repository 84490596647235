import { useBottomHeight } from "./IndicatorContext";
import { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import "./OpenQuestions.scss";
import OpenQuestion from "../models/OpenQuestion";
import useAsyncCallback from "../utils/useAsyncCallback";
import useDependentState from "../utils/useDependentState";
import { ApiService } from "../api";

interface OpenQuestionsProps {
  questions: OpenQuestion[];
  setQuestions: Dispatch<SetStateAction<OpenQuestion[]>>;
  readonly: boolean;
}

export default function OpenQuestions({
  questions: savedQuestions,
  setQuestions: setSavedQuestions,
  readonly,
}: OpenQuestionsProps) {
  useBottomHeight(65);
  const history = useHistory();

  const [questions, setQuestions] = useDependentState(savedQuestions);

  const [savedSnackbar] = useSnackbar({
    style: {
      backgroundColor: "darkgreen",
    },
  });

  const [saving, setSaving] = useState(false);
  const disabled = saving;

  const save = useAsyncCallback(
    async (s) => {
      try {
        setSaving(true);
        await ApiService.updateOpenQuestions(questions);

        if (!s.aborted) {
          history.replace("/");
          savedSnackbar("Opgeslagen", 1000);
          setSavedQuestions(questions);
        }
      } catch (e) {
        alert(e);
      } finally {
        if (!s.aborted) {
          setSaving(false);
        }
      }
    },
    [questions]
  );

  return (
    <div className="questions">
      {questions.map((question) => {
        return (
          <Question
            key={question.id}
            question={question}
            setQuestion={(newQuestion) =>
              setQuestions((questions) =>
                questions.map((oldQuestion) =>
                  oldQuestion.id === newQuestion.id ? newQuestion : oldQuestion
                )
              )
            }
            disabled={disabled}
            readonly={readonly}
          />
        );
      })}
      {readonly ? null : (
        <div className="area_buttons">
          <button onClick={save} disabled={disabled}>
            Opslaan
          </button>
        </div>
      )}
    </div>
  );
}

interface QuestionProps {
  question: OpenQuestion;
  setQuestion(question: OpenQuestion): void;
  disabled: boolean;
  readonly: boolean;
}

function Question({
  question,
  setQuestion,
  disabled,
  readonly,
}: QuestionProps) {
  return (
    <div className="question">
      <div className="question_header">
        <label
          className="statement"
          htmlFor={`question_${question.id}_checkbox`}
        >
          {question.question}
        </label>
        <input
          type="checkbox"
          id={`question_${question.id}_checkbox`}
          checked={question.checked}
          onChange={(e) => {
            if (!readonly) {
              setQuestion({ ...question, checked: e.currentTarget.checked });
            }
          }}
          disabled={disabled}
          readOnly={readonly}
        />
      </div>

      <textarea
        value={question.comment}
        placeholder="Toelichting..."
        onChange={(e) =>
          setQuestion({ ...question, comment: e.currentTarget.value })
        }
        disabled={disabled}
        readOnly={readonly}
      />
    </div>
  );
}
