import React, { ChangeEvent, ReactNode, useMemo } from "react";
import "./EditEntry.scss";
import ObservationEntry from "../models/ObservationEntry";
import formatEntryDate from "../utils/formatEntryDate";
import useAsyncMemo from "../utils/useAsyncMemo";
import { OpenAPI } from "../api";
import cameraSvg from "./images/camera.svg";

interface EditEntryProps {
  entry: ObservationEntry;
  onEntryChanged(entry: ObservationEntry): void;
  readonly: boolean;
  working: boolean;
  buttons: ReactNode;
}

export default function EditEntry({
  entry,
  onEntryChanged,
  readonly,
  working,
  buttons,
}: EditEntryProps): JSX.Element {
  const created = useMemo(
    () => formatEntryDate(entry.created),
    [entry.created]
  );

  const photoSrc =
    useAsyncMemo(
      async (signal) => {
        if (entry.photo === null) {
          return cameraSvg;
        } else if (entry.photo instanceof File) {
          return URL.createObjectURL(entry.photo);
        } else {
          const response = await window.fetch(
            `${OpenAPI.BASE}/api/entries/${entry.id}/photo${entry.photo}?thumbnail`,
            {
              signal,
              headers: new Headers({
                Authorization: `Bearer ${OpenAPI.TOKEN}`,
              }),
            }
          );
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        }
      },
      [entry.photo, entry.id]
    ) ?? "";

  function photoSelected(e: ChangeEvent<HTMLInputElement>) {
    onEntryChanged({ ...entry, photo: e.target.files![0] });
  }

  return (
    <div className="edit_entry">
      <span className="date">{created}</span>

      {readonly ? (
        entry.photo !== null ? (
          <button
            className="photo exists"
            onClick={() => {
              window
                .open(`/api/entries/${entry.id}/photo${entry.photo}`, "_blank")
                ?.focus();
            }}
          >
            <img src={photoSrc} alt="observation" />
          </button>
        ) : null
      ) : (
        <>
          <input
            id={`photo-${entry.id}`}
            type="file"
            accept="image/png, image/jpeg"
            onChange={photoSelected}
          />
          <label
            htmlFor={`photo-${entry.id}`}
            className={`photo${entry.photo !== null ? " exists" : ""}`}
          >
            <img src={photoSrc} alt="Upload" />
          </label>
        </>
      )}

      <textarea
        placeholder="Toelichting..."
        value={entry.comment}
        autoFocus={entry.id === null}
        readOnly={readonly}
        disabled={working}
        onChange={(e) => {
          onEntryChanged({ ...entry, comment: e.target.value });
        }}
      />
      {buttons}
    </div>
  );
}
