import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * useState but also changes on dependency
 */
export default function useDependentState<T>(
  dependency: T
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(dependency);
  useEffect(() => {
    setState(dependency);
  }, [dependency]);

  return [state, setState];
}
