import Area from "../../models/Area";
import { Layer, Source } from "react-map-gl";
import { GeoJSON } from "geojson";
import React from "react";

export default function AreaPolygons({ areas }: { areas: Area[] }) {
  return (
    <Source
      id="areas"
      type="geojson"
      data={{
        type: "FeatureCollection",
        features: areas.map(
          (area): GeoJSON.Feature => ({
            type: "Feature",
            geometry: area.shape,
            properties: [],
          })
        ),
      }}
    >
      <Layer
        id="area_fill"
        type="fill"
        paint={{ "fill-color": "black", "fill-opacity": 0.03 }}
      />
      <Layer
        id="area_stroke"
        type="line"
        paint={{ "line-color": "black", "line-width": 2 }}
      />
    </Source>
  );
}
