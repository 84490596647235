import React, { ReactNode, useContext, useEffect, useState } from "react";

interface IndicatorContextProps {
  bottomUIHeight: number;
  setBottomUIHeight: (height: number) => void;
}

export const IndicatorContext = React.createContext<IndicatorContextProps>({
  bottomUIHeight: 0,
  setBottomUIHeight() {},
});

export function IndicatorContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [bottomUIHeight, setBottomUIHeight] = useState(0);

  return (
    <IndicatorContext.Provider
      value={{
        bottomUIHeight,
        setBottomUIHeight,
      }}
    >
      {children}
    </IndicatorContext.Provider>
  );
}

export function useBottomHeight(height: number) {
  const { setBottomUIHeight } = useContext(IndicatorContext);
  useEffect(() => {
    setBottomUIHeight(height);
  }, [setBottomUIHeight, height]);
}
