import ObservationEntry from "../models/ObservationEntry";
import React from "react";
import "./AllEntries.scss";
import formatEntryDate from "../utils/formatEntryDate";

interface AllEntriesProps {
  entries: ObservationEntry[];
  setEntries(entries: ObservationEntry[]): void;
  setActiveEntry(index: number): void;
}

export default function AllEntries({
  entries,
  setEntries,
  setActiveEntry,
}: AllEntriesProps) {
  return (
    <div className="all_entries">
      {entries.map((entry, index) => (
        <div
          className="entry"
          key={`entry_${entry.id}`}
          onClick={() => setActiveEntry(index)}
        >
          <div className="created">{formatEntryDate(entry.created)}</div>
          <div className="comment">{entry.comment}</div>

          <button
            className="delete"
            onClick={(e) => {
              e.stopPropagation();
              if (
                window.confirm(
                  `Wilt u de observatie van ${formatEntryDate(
                    entry.created
                  )} verwijderen?`
                )
              ) {
                const newEntries = entries.filter((e) => e.id !== entry.id);
                setEntries(newEntries);
                if (newEntries.filter((e) => e.id !== null).length <= 1) {
                  setActiveEntry(0);
                }
              }
            }}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
}
