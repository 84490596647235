import { DependencyList, useState } from "react";
import useAsyncEffect from "./useAsyncEffect";

/**
 * useEffect for promises
 */
export default function useAsyncMemo<T>(
  callback: (ct: AbortSignal) => Promise<T>,
  deps?: DependencyList
): T | null {
  const [element, setElement] = useState<T | null>(null);
  useAsyncEffect(async (ct) => {
    const el = await callback(ct);
    if (!ct.aborted) {
      setElement(el);
    }
  }, deps);

  return element;
}
