import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppWrapper from "./App";
import { OpenAPI } from "./api";
import AdminRoot from "./admin/AdminRoot";
import { IndicatorContextProvider } from "./components/IndicatorContext";
import SnackbarProvider from "react-simple-snackbar";
import ApiOrigin from "./ApiOrigin";
if (window.location.hostname === "localhost") {
  OpenAPI.BASE = ApiOrigin;
}

const root = document.getElementById("root");

if (root !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>,
    root
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <SnackbarProvider>
        <IndicatorContextProvider>
          <AdminRoot />
        </IndicatorContextProvider>
      </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById("admin_root")
  );
}
