export default function formatEntryDate(date: Date): string {
  const now = new Date();
  if (
    date.getUTCFullYear() === now.getUTCFullYear() &&
    date.getUTCMonth() === now.getUTCMonth() &&
    now.getUTCDate() === date.getUTCDate()
  ) {
    return "vandaag";
  }
  return date.toLocaleDateString(undefined, {
    year: date.getFullYear() === now.getFullYear() ? undefined : "numeric",
    month: "long",
    day: "numeric",
  });
}
