import LatLng from "../../models/LatLng";
import { useMap } from "react-map-gl";
import { useEffect } from "react";

export default function Focus({
  focusCoordinate,
  availableHeight,
}: {
  focusCoordinate: LatLng | null;
  availableHeight: number;
}) {
  const mapRef = useMap();

  // Center on the focus coordinate
  useEffect(() => {
    const map = mapRef.current;

    if (map !== undefined) {
      map.stop();
      map.easeTo({
        center: focusCoordinate ?? undefined,
        padding: {
          bottom:
            (map!.getContainer().offsetHeight * (100 - availableHeight)) / 100,
          top: 0,
          left: 0,
          right: 0,
        },
      });
    }
  }, [availableHeight, focusCoordinate, mapRef]);

  return null;
}
