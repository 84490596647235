/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserData = {
    categories: Array<{
        name: string,
        description: string,
        indicators: Array<{
            code: string,
            description: string,
        }>,
    }>;
    open_questions: Array<{
        id: number,
        checked: boolean,
        comment: string,
        question: string,
    }>;
    area: {
        name: string,
        shape: any,
    };
    selection_mode: UserData.selection_mode;
    observations: Array<{
        id: string,
        coordinate: {
            lat: number,
            lng: number,
        },
        indicator: string,
        entries: Array<{
            id: number,
            comment: string,
            created: string,
            photo: string,
        }>,
        bag_id?: string | null,
        address: {
            street: string,
            house_number: string,
            postcode: string,
            city: string,
            is_exact: boolean,
        },
    }>;
}

export namespace UserData {

    export enum selection_mode {
        BAG = 'bag',
        COORDINATE = 'coordinate',
        BOTH = 'both',
    }


}
