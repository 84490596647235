import Area from "../../models/Area";
import React, { useCallback } from "react";
import { Marker } from "react-map-gl";

export default function AreaCenterMarker({
  area,
  selected,
  onAreaMarkerClicked,
}: {
  area: Area;
  selected: boolean;
  onAreaMarkerClicked(area: Area): void;
}) {
  const onClick = useCallback(() => {
    onAreaMarkerClicked(area);
  }, [onAreaMarkerClicked, area]);
  const radius = selected ? 8 : 5;
  return (
    <Marker
      longitude={area.center.lng}
      latitude={area.center.lat}
      onClick={onClick}
    >
      <svg
        style={{ cursor: "pointer" }}
        width={radius * 2 + 4}
        height={radius * 2 + 4}
      >
        <circle
          r={radius}
          cx={radius + 2}
          cy={radius + 2}
          style={{ fill: selected ? "gray" : "black", stroke: "black" }}
        />
      </svg>
    </Marker>
  );
}
