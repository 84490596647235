import React, { memo, useCallback, useMemo } from "react";

import LatLng from "../../models/LatLng";
import Area from "../../models/Area";

import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  default as MapGl,
  MapLayerMouseEvent,
  ViewStateChangeEvent,
} from "react-map-gl";

import "./Map.scss";
import MapStyle from "../../models/MapStyle";
import Focus from "./Focus";
import SelectedMarker from "./SelectedMarker";
import ObservationMarkers from "./ObservationMarkers";
import GeoLocationMarker from "./GeoLocationMarker";
import AreaCenters from "./AreaCenters";
import AreaPolygons from "./AreaPolygons";
import { SelectionMode } from "../../models/UserData";
import { Style } from "mapbox-gl";
import Address from "../../models/Address";
import { ObservationBuildings } from "./ObservationBuildings";

export interface MapView {
  zoom: number;
  center: LatLng;
}

interface BagProperties {
  aantal_verblijfsobjecten: number;
  bouwjaar: number;
  gebruiksdoel: string;
  huisletter?: string;
  huisnummer?: number;
  identificatie: string;
  openbare_ruimte?: string;
  oppervlakte?: number;
  oppervlakte_max?: number;
  oppervlakte_min?: number;
  pandidentificatie?: string;
  pandstatus?: string;
  postcode?: string;
  rdf_seealso: string;
  status: string;
  toevoeging?: string;
  woonplaats?: string;
}

export interface Building {
  bagId: string;
  address: Address | null;
}

interface MapProps {
  initialView: MapView | null;
  smallMarkers: Array<{
    id: string;
    code: string;
    coordinate: LatLng;
    bagId: string | null;
  }>;
  selectedMarker: {
    code: string;
    coordinate: LatLng;
    bagId: string | null;
    draggable: boolean;
  } | null;
  availableHeight: number;
  focusCoordinate: LatLng | null;
  areas: Area[];
  selectedArea: Area | null;
  selectionMode: SelectionMode;
  showGeoLocationMarker: boolean;
  onSelectedMarkerDragged(coordinate: LatLng): void;
  onSmallMarkerClicked(id: string): void;
  onMapClicked(coordinates: LatLng, building: null | Building): void;
  onAreaMarkerClicked(area: Area): void;
}

const Map = memo(function Map({
  initialView,
  smallMarkers,
  onSmallMarkerClicked,
  onMapClicked,
  selectedMarker,
  onSelectedMarkerDragged,
  availableHeight,
  focusCoordinate,
  areas,
  selectionMode,
  selectedArea,
  showGeoLocationMarker,
  onAreaMarkerClicked,
}: MapProps): JSX.Element {
  const onClick = useCallback(
    (e: MapLayerMouseEvent) => {
      const hasBagLayer = e.target.getLayer("bag") !== undefined;
      const features = e.target.queryRenderedFeatures(e.point, {
        layers: ["observation-circles", ...(hasBagLayer ? ["bag"] : [])],
      });
      // Marker clicked
      const marker = features.find((m) => m.layer.id === "observation-circles");
      if (marker !== undefined) {
        onSmallMarkerClicked(marker.properties!.id);
        return;
      }

      // Check if building was clicked
      const buildingFeature = features.find((m) => m.layer.id === "bag");
      let building: Building | null = null;
      if (buildingFeature !== undefined) {
        let properties = buildingFeature.properties as BagProperties;
        let address: Address | null = null;
        if (properties.openbare_ruimte !== undefined) {
          address = {
            city: properties.woonplaats ?? "",
            houseNumber: `${properties.huisnummer ?? ""}${
              properties.huisletter ?? ""
            }${properties.toevoeging ?? ""}`,
            postcode: properties.postcode ?? "",
            street: properties.openbare_ruimte,
            isExact: true,
          };
        }
        building = { bagId: properties.identificatie, address };
      }

      onMapClicked({ lat: e.lngLat.lat, lng: e.lngLat.lng }, building);
    },
    [onMapClicked, onSmallMarkerClicked]
  );
  const onMouseMove = useCallback((e: MapLayerMouseEvent) => {
    const features = e.target.queryRenderedFeatures(e.point, {
      layers: ["observation-circles"],
    });
    e.target.getCanvas().style.cursor = features.length > 0 ? "pointer" : "";
  }, []);

  const onMoveEnd = useCallback((e: ViewStateChangeEvent) => {
    window.localStorage.setItem("map_center_lat", String(e.viewState.latitude));
    window.localStorage.setItem(
      "map_center_lng",
      String(e.viewState.longitude)
    );
    window.localStorage.setItem("map_zoom", String(e.viewState.zoom));
  }, []);
  const mapStyle = useMemo<Style>(
    () => ({
      ...MapStyle,
      layers: MapStyle.layers.filter((layer) =>
        selectionMode === "bag" || selectionMode === "both"
          ? layer.id !== "building"
          : layer.id !== "bag"
      ),
    }),
    [selectionMode]
  );

  return (
    <MapGl
      mapLib={maplibregl}
      mapStyle={mapStyle}
      initialViewState={
        initialView !== null
          ? {
              zoom: initialView.zoom,
              latitude: initialView.center.lat,
              longitude: initialView.center.lng,
            }
          : {}
      }
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMoveEnd={onMoveEnd}
    >
      <Focus
        focusCoordinate={focusCoordinate}
        availableHeight={availableHeight}
      />
      <AreaPolygons areas={areas} />
      <ObservationMarkers smallMarkers={smallMarkers} />
      <ObservationBuildings
        smallMarkers={smallMarkers}
        selectedMarker={selectedMarker}
      />
      {selectedMarker !== null ? (
        <SelectedMarker
          selectedMarker={selectedMarker}
          onSelectedMarkerDragged={onSelectedMarkerDragged}
        />
      ) : null}
      <AreaCenters
        areas={areas}
        selectedArea={selectedArea}
        onAreaMarkerClicked={onAreaMarkerClicked}
      />
      {showGeoLocationMarker ? <GeoLocationMarker /> : null}
    </MapGl>
  );
});

export default Map;
