import { DependencyList, useCallback, useEffect } from "react";

/**
 * useEffect for promises
 */
export default function useAsyncEffect(
  callback: (ct: AbortSignal) => Promise<void>,
  deps?: DependencyList
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(callback, deps ?? [callback]);

  useEffect(() => {
    const abortController = new AbortController();

    cb(abortController.signal).catch((e) => {
      console.error("ERROR", e);
    });

    return () => {
      abortController.abort();
    };
  }, [cb]);
}
