import { DependencyList, useCallback, useEffect, useMemo } from "react";

/**
 * useEffect for promises
 */
export default function useAsyncCallback<T extends any[]>(
  asyncCallback: (ct: AbortSignal, ...args: T) => Promise<void>,
  deps?: DependencyList
): (...args: T) => void {
  const abortController = useMemo(() => new AbortController(), []);

  const callback = useCallback<(...args: T) => void>(
    (...args) => {
      asyncCallback(abortController.signal, ...args).then();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ?? []
  );

  useEffect(
    () => () => {
      abortController.abort();
    },
    [abortController]
  );

  return callback;
}
