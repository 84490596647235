import { useState, useEffect, DependencyList, useCallback } from "react";

interface UsePromiseOptions<T, TInitial> {
  promise: (signal?: AbortSignal) => Promise<T>;
  initial: TInitial;
  dependencies?: DependencyList;
}

type UsePromiseReturn<T, TInitial> = [
  T | TInitial,
  { isRunning: boolean; error: Error | null }
];

/**
 * Call a Promise and provide information about running & error state
 * Mostly usable for calling API's for initial loading
 */
export default function usePromise<T, TInitial = T | null>(
  options: UsePromiseOptions<T, TInitial>
): UsePromiseReturn<T, TInitial> {
  const [data, setData] = useState<T | TInitial>(options.initial);
  const [isRunning, setRunning] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const promise = useCallback(options.promise, options.dependencies || []);

  useEffect(() => {
    const controller = new AbortController();

    (async (): Promise<void> => {
      setRunning(true);
      try {
        setData(await promise(controller.signal));
      } catch (err: any) {
        setError(err);
      } finally {
        setRunning(false);
      }
    })();

    return (): void => {
      controller.abort();
    };
  }, [promise]);

  return [data, { isRunning, error }];
}
