import { Address as ApiAddress } from "../../api";
import Address from "../Address";

export function addressFromApi(address: ApiAddress): Address {
  return {
    city: address.city,
    houseNumber: address.house_number,
    isExact: false,
    postcode: address.postcode,
    street: address.street,
  };
}
