import React, { useEffect, useState } from "react";
import { GeolocateControl, GeolocateControlRef, useMap } from "react-map-gl";

export default function GeoLocationMarker() {
  const [controlRef, setControlRef] = useState<GeolocateControlRef | null>(
    null
  );

  const mapRef = useMap();
  useEffect(() => {
    if (mapRef.current !== undefined && controlRef !== null) {
      mapRef.current.once("idle", () => controlRef.trigger());
    }
  }, [mapRef, controlRef]);

  return (
    <GeolocateControl
      style={{ display: "none" }}
      showUserHeading={true}
      ref={setControlRef}
      positionOptions={{ enableHighAccuracy: true }}
      trackUserLocation={true}
    />
  );
}
