import "./Login.scss";
import { Link } from "react-router-dom";
import "./Terms.scss";
export default function Terms(): JSX.Element {
  return (
    <div className="terms">
      <Link to="/" className="back">
        ← Terug
      </Link>
      <h3>Gebruikersvoorwaarden Signaleringstool.nl</h3>
      <p>
        De observatietool ‘signaleringstool.nl’ is een door het lectoraat
        Ondermijning van Avans Hogeschool ontwikkeld instrument om op een
        laagdrempelige manier kwetsbare locaties en gelegenheden voor
        ondermijning geografisch in kaart te brengen.
      </p>
      <p>
        In de tool is een set objectieve indicatoren van kwetsbare locaties en
        gelegenheden voor ondermijning opgenomen. Middels een of meerdere
        observatiemomenten worden deze gelegenheden en kwetsbaarheden
        geografisch in kaart gebracht en voorzien van een toelichting. Hierbij
        wordt geen naar personen herleidbare data verzameld of verwerkt.
      </p>
      <p>
        Met de observatietool wordt de geografische spreiding en concentratie
        van ‘hot spots’ visueel zichtbaar in een kaart van het gebied. Deze data
        kan worden geëxporteerd naar Excel voor verdere analyse, bijvoorbeeld
        door deze te vergelijken met bronnen als interviews en systeemdata. Zo
        ontstaat een informatiebeeld van bijvoorbeeld een bedrijventerrein of
        winkelgebied in het kader van de ‘Handreiking Informatiebeeld’ van de
        Taskforce-RIEC Zeeland-Brabant, te vinden op het Kennisplatform
        Ondermijning.
      </p>
      <p>
        De tool is ontwikkeld om gebruikt te kunnen worden door studenten, maar
        ook gebiedsgerichte professionals zoals handhavers en wijkagenten, om
        een rijk informatiebeeld op te bouwen voor hun werkgebied.
      </p>
      <p>
        Deze gebruikersvoorwaarden beschrijven uw rechten en
        verantwoordelijkheden bij het gebruik van ‘signaleringstool.nl’ en geeft
        aan wat u van ons kunt verwachten.
      </p>
      <p>
        Door te klikken op “ik accepteer” of door gebruik te maken van
        ‘signaleringstool.nl’ in welke vorm dan ook, gaat u akkoord met deze
        voorwaarden.
      </p>

      <h3>Artikel 1 : definities</h3>
      <dl>
        <dt>Signaleringstool.nl</dt>
        <dd>
          de observatietool ‘signaleringstool.nl’ is een door het lectoraat
          Ondermijning van Avans Hogeschool ontwikkeld instrument om op een
          laagdrempelige manier kwetsbare locaties en gelegenheden voor
          ondermijning geografisch in kaart te brengen. Er is sprake van een
          prototype!
        </dd>
        <dt>Aanbieder/wij</dt>
        <dd>
          lectoraat Ondermijning van Avans Hogeschool, gevestigd aan de Hugo de
          Grootlaan 37 te ’s-Hertogenbosch, onderdeel van het expertisecentrum
          Veiligheid van Avans Hogeschool.
        </dd>
        <dt>Gebruiker/u</dt>
        <dd>
          de gebruiker van ‘signaleringstool.nl’ zowel als online app of als
          standalone software
        </dd>
      </dl>

      <h3>Artikel 2 Doel signaleringstool.nl</h3>
      <ol>
        <li>
          Het doel van de signaleringstool.nl is slechts en alleen het op
          eenvoudige wijze vastleggen van observaties.
        </li>
        <li>
          De observaties dienen te allen tijde door menselijke tussenkomst te
          worden geïnterpreteerd. Er vindt geen geautomatiseerde besluitvorming
          plaats.
        </li>
        <li>
          In signaleringstool.nl worden en mogen uitdrukkelijk geen
          persoonsgegevens worden verwerkt. Dit betekent dat een Gebruiker geen
          mensen op foto’s mag hebben die in signaleringstool.nl kunnen worden
          geüpload en in de aantekeningen geen persoonskenmerken mogen worden
          opgenomen. Gebruiker dient de instructies in signaleringstool.nl
          volledig op te volgen.
        </li>
      </ol>

      <h3>Artikel 3 Kosten en data</h3>
      <ol>
        <li>
          We bieden onze signaleringstool.nl gratis aan, maar houd er rekening
          mee dat de normale tarieven van uw telecomaanbieder (zoals voor sms en
          datakosten) gewoon van toepassing kunnen zijn.
        </li>
        <li>
          U verleent toestemming en alle rechten die nodig zijn zodat de door
          Gebruiker verzamelde data door Aanbieder gebruikt kan worden voor
          verbetering van de signaleringstool.nl.
        </li>
      </ol>

      <h3>Artikel 4 Speciale bepalingen voor software</h3>
      <ol>
        <li>
          Als u onze software downloadt of gebruikt, zoals een stand-alone
          softwareproduct of een app, gaat u ermee akkoord dat de software zo nu
          en dan upgrades, updates en extra functies van ons downloadt en
          installeert om de software te verbeteren, uit te breiden en verder te
          ontwikkelen.
        </li>
        <li>
          Het is niet toegestaan broncode te wijzigen, er afgeleide werken van
          te maken, te decompileren of anderszins te verkrijgen, behalve wanneer
          dit uitdrukkelijk is toegestaan volgens een open-bronlicentie of
          wanneer we u uitdrukkelijk schriftelijke toestemming hebben gegeven.
        </li>
        <li>
          Indien en voor zover de Gebruiker de beschikking krijgt over de
          broncode van signaleringstool.nl en deze zelf beheert, draagt
          Aanbieder geen enkele verantwoordelijkheid meer voor enige werking van
          de tool, meer in het bijzonder niet voor de indicatoren welke door
          Gebruiker zelf worden bepaald en ingesteld.
        </li>
      </ol>

      <h3>Artikel 5 Beëindiging.</h3>
      <ol>
        <p>
          Als u de letter of geest van deze Gebruikersvoorwaarden schendt of op
          andere wijze een risico of mogelijke juridisch risico voor ons
          creëert, kunnen we uw toegang tot signaleringstool.nl geheel of
          gedeeltelijk stopzetten. We zullen u hierover via e-mail informeren of
          wanneer u de volgende keer probeert uw account te openen.
        </p>
      </ol>
      <h3>Artikel 6 Aansprakelijkheid</h3>
      <ol>
        <li>
          We proberen signaleringstool.nl foutvrij en veilig te houden maar het
          gebruik is op eigen risico.
        </li>
        <li>
          We bieden signaleringstool.nl zoals het wordt aangeboden (As is)
          zonder expliciete of impliciete garanties.
        </li>
        <li>
          Wij zijn niet aansprakelijk voor enige schade die – direct of indirect
          – het gevolg is van het gebruik van signaleringstool.nl door
          gebruiker, tenzij die schade het gevolg is van de bewuste
          roekeloosheid of opzet van Aanbieder.
        </li>
        <li>
          Gebruiker vrijwaart Aanbieder ter zake van alle schade die Aanbieder
          lijdt ten gevolge van aanspraken van derden als gevolg van of
          samenhangende met het gebruik van signaleringstool.nl door Gebruiker,
          tenzij die schade het gevolg is van de bewuste roekeloosheid of opzet
          Aanbieder.
        </li>
      </ol>

      <h3>Artikel 7 Wijzigingen</h3>
      <ol>
        <li>
          Wij behouden ons het recht voor om wijzigingen aan te brengen in deze
          verklaring. Het verdient aanbeveling om deze verklaring geregeld te
          raadplegen, zodat u van deze wijzigingen op de hoogte bent.
        </li>
        <li>
          Wanneer u na melding van deze wijzigingen in onze voorwaarden,
          richtlijnen of beleidsregels de signaleringstool.nl blijft gebruiken,
          verklaart u akkoord te gaan met de gewijzigde voorwaarden.
        </li>
      </ol>

      <h3>Artikel 8 Overige bepalingen</h3>
      <ol>
        <li>
          Op deze Gebruikersvoorwaarden is Nederlands recht van toepassing
        </li>
        <li>
          Eventuele geschillen kunnen uitsluitend voorgelegd worden aan de
          bevoegde rechter in Nederland.
        </li>
      </ol>

      <p>
        Voor meer informatie of om gebruik te maken van signaleringstool.nl kan
        u contact opnemen met het Lectoraat ondermijning van Avans via{" "}
        <a href="mailto:sefj.janssen@avans.nl">sefj.janssen@avans.nl</a>
      </p>
    </div>
  );
}
