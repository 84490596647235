import Area from "../../models/Area";
import AreaCenterMarker from "./AreaCenterMarker";
import React from "react";

export default function AreaCenters({
  areas,
  selectedArea,
  onAreaMarkerClicked,
}: {
  areas: Area[];
  selectedArea: Area | null;
  onAreaMarkerClicked(area: Area): void;
}) {
  return (
    <>
      {areas.map((area) => (
        <AreaCenterMarker
          key={`area-${area.name}`}
          area={area}
          selected={area === selectedArea}
          onAreaMarkerClicked={onAreaMarkerClicked}
        />
      ))}
    </>
  );
}
