import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Root from "./Root";
import { IndicatorContextProvider } from "./components/IndicatorContext";
import SnackbarProvider from "react-simple-snackbar";
import UserSessionProvider from "./components/UserSessionContext";

function App() {
  return (
    <BrowserRouter>
      <UserSessionProvider>
        <SnackbarProvider>
          <IndicatorContextProvider>
            <Root />
          </IndicatorContextProvider>
        </SnackbarProvider>
      </UserSessionProvider>
    </BrowserRouter>
  );
}

export default App;
