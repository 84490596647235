import { FormEvent, useRef, useState } from "react";
import "./Login.scss";
import { Link } from "react-router-dom";

interface LoginProps {
  onLogin(password: string): Promise<void>;
}

export default function Login({ onLogin }: LoginProps): JSX.Element {
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const ref = useRef<HTMLFormElement>(null);

  async function login(e: FormEvent) {
    e.preventDefault();
    try {
      setLoggingIn(true);
      await onLogin(password);
    } finally {
      if (ref.current) {
        setLoggingIn(false);
      }
    }
  }

  return (
    <form className="login" onSubmit={login} ref={ref}>
      <img src="/logo512.png" alt="logo" />
      <label htmlFor="password">Wachtwoord:</label>
      <input
        id="password"
        disabled={loggingIn}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.currentTarget.value)}
      />
      <button type="submit" disabled={loggingIn || password.length === 0}>
        Inloggen
      </button>

      <Link to="/terms">Gebruikersvoorwaarden</Link>
    </form>
  );
}
